<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="typeTable"
          title="평가항목종류"
          tableId="typeTable"
          :columns="typegrid.columns"
          :data="typegrid.data"
          @rowClick="rowClick"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          selection="multiple"
          rowKey="col1"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" icon="add" @btnClicked="addrow1" />
              <c-btn v-if="editable" icon="remove" @btnClicked="removerow1" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-table
          ref="itemTable"
          title="평가항목"
          tableId="itemTable"
          :columns="itemgrid.columns"
          :data="itemgrid.data"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="col2"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow2" />
              <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="removerow2" />
              <c-btn v-if="editable" label="저장" icon="save"  />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">평가항목 종류</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="typegridcol1" autofocus @keyup.enter="prompt = false" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="CANCEL" @click="cancelTypegridcol1" />
          <q-btn flat label="SET" @click="setTypegridcol1" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'equipment-class-item',
  data() {
    return {
      prompt: false,
      typegridcol1: '',
      typegrid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '평가항목종류',
            align: 'center',
            sortable: false,
          }
        ],
        data: [],
      },
      itemgrid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '평가항목종류',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col12',
            label: '평가항목',
            align: 'left',
            style: 'width:500px',
            type:'textarea',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '배점',
            align: 'center',
            style: 'width:60px',
            type:'number',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '순서',
            align: 'center',
            type:'number',
            style: 'width:60px',
            sortable: false,
          },
        ],
        data: [],
      },
      itemData: {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getTypeList();
    },
    cancelTypegridcol1() {
      this.typegridcol1 = '';
      this.prompt = false;
    },
    setTypegridcol1() {
      this.prompt = false;
      this.typegrid.data.splice(0,0,{
        col1: this.typegridcol1,
      });
      this.typegridcol1 = '';
    },
    rowClick(row) {
      this.getItemList(row.col1);
    },
    getTypeList() {
      this.typegrid.data = [
        {
          col1: '안전에 관한 사항',
        },
        {
          col1: '작업에 관한 사항',
        },
      ];
    },
    getItemList(_row) {
      this.itemgrid.data = [
        {
          col1: _row,
          col2: '항목1',
          col3: '10',
          col4: '1',
        },
        {
          col1: _row,
          col2: '항목2',
          col3: '5',
          col4: '2',
        },
      ];
    },
    rowClick2(row) {
      this.itemData = row
    },
    reset() {
      Object.assign(this.$data.itemData, this.$options.data().itemData);
    },
    addrow1() {
      this.prompt = true;
    },
    removerow1() {
      let selectData = this.$refs['typeTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.typegrid.data = this.$_.reject(this.typegrid.data, { col1: item.col1 })
        })
      }
    },
    addrow2() {
      // 초기에는 userId에 tempId를 부여
      this.itemgrid.data.splice(0, 0, {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
      })
    },
    removerow2() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.itemgrid.data = this.$_.reject(this.itemgrid.data, { col2: item.col2 })
        })
      }
    },
  }
};
</script>
